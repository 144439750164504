import { Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { displayLoader } from "../../store/Action/loader.action";
import { StateType } from "../../store/Reducer/index.reducer";
import { getAllCategories } from "../../store/api/category";
import { useTranslation } from "react-i18next";
import { MEDIA_IMAGE_URL } from "../../constant";
import Navbar from "../../components/Navbar";
import Loader from "../../components/Loader";
import DrawerForm from "../../components/DrawerForm";
import CategoryBlock from "../../components/Category/categoryBlock";
import Footer from "../../components/Footer";
import { getAllGuestRequestCategories } from "../../store/api/guestReqCategory";

const GuestRequest = () => {
    const { t } = useTranslation("category");
    const dispatch: any = useDispatch();
    const location = window.location.pathname.includes("/guest-request");
    const { currentVenue, zone } = useParams();
    let table = sessionStorage.getItem("tablenum");
    let user = sessionStorage.getItem("inputName");
    let phonenum = sessionStorage.getItem("phone");

    const homeData = useSelector((state: StateType) => state.profile?.profile);
    const { guestReqCategoryRes, loader } = useSelector(
        (state: StateType) => state?.guestReqCategory
    );

    const [visible, setVisible] = useState(false);
    const [inputTableNum, setInputTableNum] = useState(0);
    const [inputName, setInputName] = useState("");
    const [tablenum, setTableNum] = useState(0);
    const [phoneNum, setPhoneNum] = useState("");
    const [validationMsgTwo, setValidationMsgTwo] = useState("");
    const [validationMsgThree, setValidationMsgThree] = useState("");

    const onSubmit = () => {
        if (
            homeData?.data?.profile?.table?.filter(
                (data: any) => data?.key == inputTableNum
            )?.length
        ) {
            setTableNumber(inputTableNum);
        }
        if (!inputName) {
            setValidationMsgTwo("Please provide your fullname");
            return;
        }
        if (!phoneNum) {
            setValidationMsgThree("Please enter your contact number");
        }
        {
            setTableNumber(inputTableNum);
        }
    };
    const onChangeTwo = (e: any) => {
        {
            if (!e.target.value) {
                setValidationMsgTwo("Please provide your fullname");
            } else {
                setValidationMsgTwo("");
            }
            setInputName(e.target.value);
        }
    };
    const onChangeThree = (e: any) => {
        {
            if (!e.target.value) {
                setValidationMsgThree("Please enter your contact number");
            } else {
                setValidationMsgThree("");
            }
            setPhoneNum(e.target.value);
        }
    };
    const showDrawer = () => {
        setVisible(true);
    };
    useEffect(() => {
        dispatch(displayLoader(true));
        dispatch(getAllGuestRequestCategories(currentVenue));
    }, [dispatch]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
        });
    }, []);

    React.useEffect(() => {
        if (table) {
            let newtable = JSON.parse(table);
            setTableNum(newtable);
        }
        if (user) {
            setInputName(user);
        }
        if (phonenum) {
            setPhoneNum(phoneNum);
        }
    }, [table, user, phonenum]);

    const setTableNumber = (tableNum: number) => {
        setInputTableNum(tableNum);
        sessionStorage.setItem("tablenum", JSON.stringify(tableNum));
        sessionStorage.setItem("inputName", inputName);
        sessionStorage.setItem("phone", JSON.stringify(phoneNum));
    };
    return (
        <>
            <Navbar showLogo showNavMenu venue={currentVenue} />
            {loader ? (
                <Loader classType="loading-spinner" />
            ) : (
                <div className="whole_wrapper">
                    <div className="category container">
                        <div className="category-cover">
                            <img
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = "/Images/catcover.png";
                                }}
                                src={
                                    homeData?.data?.profile?.banner ==
                                    "/images/user/"
                                        ? "/Images/catcover.png"
                                        : `${MEDIA_IMAGE_URL}/images/banner/${homeData?.data?.profile?.banner}`
                                }
                                alt="category-cover"
                            />
                        </div>
                        <div className="delivery_content">
                            <div className="delivery_category">
                                {location}
                                {t("default:common.buttons.guestRequest")}
                            </div>
                        </div>

                        <div className="top-content ">
                            <div className="table-num ">
                                {tablenum
                                    ? `${
                                          homeData?.data?.profile?.type == 2
                                              ? t("room")
                                              : homeData?.data?.profile?.type ==
                                                4
                                              ? t("room")
                                              : homeData?.data?.profile?.type ==
                                                3
                                              ? "tee"
                                              : "table"
                                      } ${tablenum}`
                                    : `${
                                          homeData?.data?.profile?.type == 2
                                              ? t("selectRoom")
                                              : homeData?.data?.profile?.type ==
                                                4
                                              ? t("selectRoom")
                                              : homeData?.data?.profile?.type ==
                                                3
                                              ? t("selectTee")
                                              : t("selectTable")
                                      }`}
                            </div>
                            <div className="table-btn " onClick={showDrawer}>
                                {tablenum
                                    ? t("default:common.buttons.change")
                                    : t("default:common.buttons.select")}
                            </div>
                        </div>
                        <DrawerForm
                            title={
                                zone == "in-room" || zone == "guest-request"
                                    ? t("roomNumTitle")
                                    : t("whereSeated")
                            }
                            label={
                                zone == "in-room" || zone == "guest-request"
                                    ? t("roomNum")
                                    : t("tableNum")
                            }
                            placeholder={
                                zone == "in-room" || zone == "guest-request"
                                    ? `${t("placeholder.roomNum")}`
                                    : `${t("placeholder.tableNum")}`
                            }
                            visible={visible}
                            onClose={() => setVisible(false)}
                            onSubmit={onSubmit}
                            onChangeOne={(e: any) =>
                                setInputTableNum(e.target.value)
                            }
                            onChangeTwo={(e: any) => onChangeTwo(e)}
                            onChangeThree={(e: any) => onChangeThree(e)}
                            validationMsgOne={
                                !inputTableNum ||
                                homeData?.data?.profile?.table?.filter(
                                    (data: any) => data.key == inputTableNum
                                )?.length
                                    ? ""
                                    : `
                                    ${
                                        zone == "inroom" ||
                                        zone == "guest-request"
                                            ? t("validation.roomNum")
                                            : t("validation.tableNum")
                                    }`
                            }
                            validationMsgTwo={validationMsgTwo}
                            validationMsgThree={validationMsgThree}
                            buttondisabled={
                                homeData?.data?.profile?.type == 3
                                    ? homeData?.data?.profile?.table?.filter(
                                          (data: any) =>
                                              data.key == inputTableNum
                                      )?.length &&
                                      inputName.length &&
                                      phoneNum
                                        ? false
                                        : true
                                    : !homeData?.data?.profile?.table?.filter(
                                          (data: any) =>
                                              data.key == inputTableNum
                                      )?.length
                            }
                        />
                        <div className="category_container">
                            {guestReqCategoryRes?.categories?.map(
                                (item, index) => {
                                    return (
                                        <div className="food-items" key={index}>
                                            <Link
                                                to={
                                                    item.can_order == 0
                                                        ? "#"
                                                        : `/menu/${currentVenue}/guest-request/listingmenu/${item.id}/${item.restaurant_id}`
                                                }
                                            >
                                                <CategoryBlock
                                                    imgsrc={
                                                        item.photo
                                                            ? `${MEDIA_IMAGE_URL}/images/category/${item.photo}`
                                                            : "/Images/noimg-1.svg"
                                                    }
                                                    item_name={item.name}
                                                    can_order={
                                                        item.can_order == 0
                                                            ? "Ordering Not Availiable"
                                                            : ""
                                                    }
                                                    end_time={item.end_time}
                                                    location
                                                />
                                            </Link>
                                        </div>
                                    );
                                }
                            )}
                        </div>
                    </div>
                    <Footer />
                </div>
            )}
        </>
    );
};

export default GuestRequest;
const date = new Date();
export const device_time =
    date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
